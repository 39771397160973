<template>
	<div id="tu-availability">
		<el-row :gutter="20">
			<el-col :span="4">
		    <SecondMenu />
			</el-col>
			<el-col :span="10">
				<div class="tu-title">
					<div v-if="zone == 2">Set your availability in UK time</div>
					<div v-else>Set your availability in CN time</div>
					<!-- <div>Set your availability time</div> -->
					<div></div>
				</div>
				<div class="tu-content" v-loading="loading">
					<div class="tu-form" v-for="week, index in form">
						<div class="tu-week"><el-checkbox @change="changeWeekCheckbox" v-model="checkbox[index]" :label="index" /></div>
						<template v-for="item, i in week">
							<div class="select-time">
								<el-time-select
									v-model="item.start_time"
									placeholder="Start time"
									start="00:00"
									step="00:30"
									end="23:30"
									size="small"
									@change=""
								>
								</el-time-select>
								<el-time-select
									v-model="item.stop_time"
									:min-time="item.start_time"
									placeholder="End time"
									start="00:00"
									step="00:30"
									end="23:30"
									size="small"
								>
								</el-time-select>

								<div style="margin-left: 15px;">
									<el-icon class="operation-icon"><i class="fa fa-trash" @click="trash(index, i)"></i></el-icon>
									<el-icon v-if="i==week.length-1" class="operation-icon"><i class="fa fa-plus" @click="plus(index)"></i></el-icon>
								</div>
							</div>
						</template>
					</div>
					<div style="padding: 15px;">
						<el-button type="primary" @click="setAvailability">Save</el-button>
						<el-button v-if="$store.getters.userType==2 && $store.getters.userInfo.is_pass != 1" type="danger" @click="nextStepProgess">
							Save & Next step
						</el-button>
					</div>
				</div>
			</el-col>
			<el-col :span="10">
				<div style="text-align: left;">
					<h2>
						Students use your availability to find and contact you
					</h2>
					<p>
						Students can only request lessons during your available time slots when you have no lessons booked in.
					</p>
					<p>
						Regularly update your availability to receive more relevant matches and appear higher in search results.
					</p>
					<p>
						Ignored or rejected lesson requests will decrease the visibility of your profile.
					</p>
				</div>
			</el-col>
		</el-row>
	</div>
</template>
<script>
import SecondMenu from '@/components/SecondMenu'
import { mapActions, mapGetters } from 'vuex'
import { ElMessageBox } from 'element-plus'
import TeacherApi from '@/api/teacher'
import TimeControl from '@/utils/time-control'

export default {
	name: "availability",
	components: {
		SecondMenu,
		ElMessageBox
	},
	data() {
		return {
			loading: true,
			form: {
				Monday: [],
				Tuesday: [],
				Wednesday: [],
				Thursday: [],
				Friday: [],
				Saturday: [],
				Sunday: []
			},
			checkbox: {
				Monday: false,
				Tuesday: false,
				Wednesday: false,
				Thursday: false,
				Friday: false,
				Saturday: false,
				Sunday: false
			}
		}
	},
	created() {
		this.getAvailability();
	},
	computed: {
		...mapGetters([
			'userInfo'
		]),
		zone() {
			let zone = TimeControl.zone();
			if(this.userInfo) {
				zone = this.userInfo.timezone;
			}
			return TimeControl.getZoneCode(zone);
		}
	},
	methods: {
		changeWeekCheckbox(value, event) {
			let label = event.target.value
			if(!!!value) {
				ElMessageBox.confirm("Delete the current all time?", "Prompt").then(() => {
					this.form[label] = [];
				}).catch(() => {
					this.checkbox[label] = true;
				});
			} else {
				this.form[label].push({start_time: "08:00", stop_time: "09:00"});
			}
		},
		trash(index, i) {
			this.form[index].splice(i, 1);
			if(this.form[index].length <= 0) {
				this.checkbox[index] = false;
				this.form[index] = [];
			}
		},
		plus(index) {
			this.form[index].push({start_time: "08:00", stop_time: "09:00"});
		},
		getAvailability() {
			this.loading = true;
			TeacherApi.availability("GET").then((res) => {
				this.loading = false;
				if(res.data) {
					this.form.Monday = res.data.Monday || []					
					this.form.Tuesday = res.data.Tuesday || []
					this.form.Wednesday = res.data.Wednesday || []
					this.form.Thursday = res.data.Thursday || []
					this.form.Friday = res.data.Friday || []
					this.form.Saturday = res.data.Saturday || []
					this.form.Sunday = res.data.Sunday || []
					if(this.form.Monday.length > 0) {
						this.checkbox.Monday = true
					}
					if(this.form.Tuesday.length > 0) {
						this.checkbox.Tuesday = true
					}
					if(this.form.Wednesday.length > 0) {
						this.checkbox.Wednesday = true
					}
					if(this.form.Thursday.length > 0) {
						this.checkbox.Thursday = true
					}
					if(this.form.Friday.length > 0) {
						this.checkbox.Friday = true
					}
					if(this.form.Saturday.length > 0) {
						this.checkbox.Saturday = true
					}
					if(this.form.Sunday.length > 0) {
						this.checkbox.Sunday = true
					}
				}
			})
		},
		setAvailability(next) {
			if(next !== true) {
				next = false;
			}
			TeacherApi.availability('POST', this.form).then((res) => {
				this.$message.success(this.$t('Save Success'));
				if(next) {
					this.$store.dispatch('GetUserInfo');
					this.$router.push('/tu/account')
				}
			})
		},
		nextStepProgess() {
			this.setAvailability(true);
		}
	}
}
</script>
<style lang="less">
#tu-availability {

	.tu-title {
		background: var(--el-color-primary);
		padding: 17px 15px;
		color: var(--el-color-white);
		text-align: left;
		font-weight: 700;
		display: flex;
		flex-direction: row;
		justify-content: space-between;
		align-items: center;
	}

	.tu-content {
		background: var(--el-color-white);
		text-align: left;

		.tu-form {
			// margin-top: 15px;
			// border: var(--el-border-base);
			margin-bottom: -1px;
			.tu-week {
				// background: var(--el-color-info-light);
    			padding: 0 15px;
			}

			.select-time {
				display: flex;
				flex-direction: row;
				padding: 5px 0;
				align-items: center;
				flex-wrap: nowrap;
			    align-content: center;
			    justify-content: flex-start;

			    .el-select {
			    	width: 180px;
			    	margin-left: 15px;
			    }

				.operation-icon {
					padding: 0 5px;
					cursor: pointer;
				}
			}
		}

	}
}
</style>